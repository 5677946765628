import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import footerStyles from './footer.module.scss'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `)
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.innerFooter}>
            <div className={footerStyles.copyright}>
                <div className={footerStyles.innerCopyright}>
                <div className={footerStyles.fbFollow}>
                    <a href="https://www.facebook.com/immaculatemarymty/" target="_blank"><FontAwesomeIcon icon={faFacebook} /> Follow Us</a>
                </div>
                <div>
                    <p>Created by {data.site.siteMetadata.author}, Copyright 2021</p>
                </div>
                </div>
            </div>
            </div>
        </footer>
    )
}

export default Footer